import dayjs from 'dayjs';

export const getDaysMonth = (year: number, month: number) => {
  const endDate = new Date(year, month, 0);
  const numberDays = endDate.getDate();
  return Array.from({ length: numberDays }, (_, i) => (i + 1).toString().padStart(2, '0'));
};

export const getDays = (startDate: string, endDate: string) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const days = [];
  for (let day = start; day <= end; day = day.add(1, 'day')) {
    days.push(day.format('DD/MM'));
  }
  return days;
};

export const months = [
  { value: 1, name: 'Janeiro'},
  { value: 2, name: 'Fevereiro'},
  { value: 3, name: 'Março'},
  { value: 4, name: 'Abril'},
  { value: 5, name: 'Maio'},
  { value: 6, name: 'Junho'},
  { value: 7, name: 'Julho'},
  { value: 8, name: 'Agosto'},
  { value: 9,  name: 'Setembro'},
  { value: 10,  name: 'Outubro'},
  { value: 11, name: 'Novembro'},
  { value: 12, name: 'Dezembro'},
];
