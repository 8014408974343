import { Divider, Fab, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';
import { Box } from '@mui/system'
import React, { useState, useMemo } from 'react'
import './Atendimentos.css'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AppBarPrincipal from '../../../components/appBar/Appbar'
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import RequestCard from '../../../components/requests/cards/RequestCard'
import RequestClient from '../../../components/requests/requestsModais/requestClient/requestClient';
import { useDebounce } from '../../../hooks/useDebounce/useDebounce';
import { useCryptoSessionStorage } from '../../../hooks/useCryptoSessionStorage';
let url = process.env.REACT_APP_API+ "/requests/client";

const Atendimentos = (props) => {
  const { getData } = useCryptoSessionStorage();
  const client = getData("client") || {};
  const [tab, setTab] = useState('1');
  const [changed, setChanged ] = useState(false); 
  const { data: requests } = useFetchData(url, {user: client?.id}, changed, false);
  const [openNewRequest, setOpenNewRequest] = useState(false);
  const [values, setValues] = useState({});
  const { debounce } = useDebounce ();

  const handleClickOpenRequest = () => {
    
    let newRequest = {
      sequence: null,
      description: '',
      status: 1,
      type_service: 30,
      type_service_description: 'Acesso Remoto',
      type_request: 0,
      hide_panel: 'N',
    };
    
    setValues(newRequest);      
    setOpenNewRequest(true);    
  }

  const pendingRequests = useMemo(() => {
    return requests && requests?.filter((item) => item.status < 4);
  },[requests])

  const concludedRequests = useMemo(() => {
    return requests && requests?.filter((item) => item.status > 3);
  }, [requests]) 

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChanged = () => {
    setChanged(!changed);     
  }

  debounce((30*1000), () => {
    handleChanged();
  });

  return (
    <div className="atendimento">
          <AppBarPrincipal handleChanged={handleChanged} requestsPage={true}/> 
          <div class="fab"  ontouchstart=""  >
          <Box 
            title='Novo atendimento'
            sx={{ flexGrow: 1, }}  onClick={handleClickOpenRequest} >
            <Fab className='add' size="small"  aria-label="add"             
              sx={{
              color: 'common.white',
              bgcolor: green[500],
              '&:hover': {
                bgcolor: green[600],
              }}}>
            <AddIcon />
            </Fab>
          </Box>
          </div> 
          <RequestClient open={openNewRequest} setOpen={setOpenNewRequest}  values={values} setValues={setValues}  admin={false}  requestsPage={true}  handleChanged={handleChanged}/>          
          <Box className="header-componentes-user">
            <Typography variant="h4"  color="text.secondary" >
              Consulta de Atendimentos 
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Verifique o andamento de suas solicitações e consulte atendimentos antigos.   
            </Typography>
          </Box>

          <Divider/>
          <Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                    <Tab label="Em aberto" value="1" />
                    <Tab label="Concluídos" value="2" />
                    {/* <Tab label="Item Three" value="3" /> */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box className="atendimentos-abertos">
                  <Grid container spacing={2} columns={ { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 } } >
                    {pendingRequests && pendingRequests?.map((item) => {
                    return ( 
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>  
                        <RequestCard values={item} admin={false}/>
                      </Grid>
                    )})}
                  </Grid>
                </Box> 
                </TabPanel>
                <TabPanel value="2">
                <Grid container spacing={2} columns={ { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 } } >
                    {concludedRequests && concludedRequests?.map((item) => {
                    return ( 
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>  
                        <RequestCard values={item} admin={false}/>
                      </Grid>
                    )})}
                  </Grid>
                </TabPanel>
                {/* <TabPanel value="3">Item Three</TabPanel> */}
              </TabContext>
            </Box>   
          </Box>              
    </div>
  )
}

export default Atendimentos