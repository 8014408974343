import { Button, Chip, Dialog, DialogActions, DialogTitle, MenuItem, SpeedDial, SpeedDialIcon, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, {  useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import CustomSelect from '../../../shared/CustomSelect';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { levelsSupport } from '../../../../helpers';

let url = process.env.REACT_APP_API+'/fr';

const RequestsFrequent = (props) => {
    const [changed, setChanged] = useState(false);
    const { data: listRequest, editData, postData, deleteData } = useFetchData(url, {}, changed)
    const [filter, setFilter] = useState('');
    const [request, setRequest] = useState({});
    const [open, setOpen] = useState(false)
    const { ShowMessage } = useShowMessages();

    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeValues = (values) => {
        setRequest((prevValues) => (
            {
                ...prevValues, [values.target.name]: values.target.value,
            }
        ))
    }

    const handleEdit = (item) => {
        setRequest(item);
        setOpen(true);
    }

    const handleClickSend = async (values) => {

        let res;
        res = (values?.id > 0) ? await editData(url, values) : await postData(url, values)
        if(res.success){
            ShowMessage('success', res.msg);
            setChanged(!changed);
            handleClose();
          }else{
              ShowMessage('error', res.msg);
        }
        
    }

    const handleDelete = async (value) => {
        await deleteData(url, {id: value.id}).then((res) => {
            if(res.success){
                ShowMessage('success', res.msg);
                setChanged(!changed);
              }else{
                  ShowMessage('error', res.msg);
            }
        })
    }

    const handleNewRequest = async () => {
       setRequest({});
       setOpen(true);
    }

    const validationSchema = Yup.object({
        description: Yup.string()
          .required("Informe a descrição")
          .typeError("Descrição inválida"),
      
        level: Yup.number()
          .required("Informe o nível de suporte") 
          .typeError("Nível Inválido"),	
      
    });

    const initialValues = {
        id: request.id || 0,
        description: request.description || '',
        level: request.level || 0,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
          const reqBody = new FormData();
          Object.keys(values).forEach((key) => {
            reqBody.append(key, values[key]);
          });
          handleClickSend(values);
        },
    });
    
  return (
    <Box sx={{m: 3}}>
        <Typography variant='h5' padding={2}>Solicitações frequentes</Typography>
        <Box fullWidth justifyContent="center" alignItems="center"   >
            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: "95%", m: 2 }}  >
                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField 
                    id="input-with-sx" 
                    label="Pesquisar" 
                    variant="standard" 
                    fullWidth
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </Box>
        </Box>

        <Dialog open={open} className='dialog-new-motivo' >
            <form onSubmit={formik.handleSubmit}>
            <DialogTitle>Cadastro de Solicitações</DialogTitle>
            <Box sx={{m: 1}}>
                <TextField
                    className='label-description'
                    autoFocus
                    name="description"
                    value={request.description}
                    defaultValue=" "
                    label="Descrição da Solicitação"
                    type="text"
                    onChange={(e) => handleChangeValues(e)}
                    fullWidth
                    variant="outlined"
                    sx={{marginBottom: 2}}
                />
                {/* <TextField
                    className='label-ordem'
                    autoFocus
                    name="order"
                    value={request.order}
                    defaultValue=" "
                    label="Ordem"
                    type="number"
                    onChange={(e) => handleChangeValues(e)}
                    fullWidth
                    variant="outlined"
                /> */}
                <CustomSelect 
                    id="level" 
                    label="Nível de Suporte" 
                    formik={formik} 
                    placeholder="Escolha o nível" 
                    fullWidth  
                    options={levelsSupport}
                />
            </Box>         
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit">Salvar</Button>
            </DialogActions>
            </form>  
        </Dialog>
        <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
        {listRequest && listRequest?.filter((i) => i.description?.toLowerCase().includes(filter?.toLowerCase())).map((item) =>(
            <Chip 
                label={item.description} 
                variant="outlined" 
                onClick={() => handleEdit(item)}
                onDelete={() => handleDelete(item)}
                deleteIcon={<DeleteIcon />}
                
            />   
        ))     
        }
        </Box>
        <SpeedDial
            
            ariaLabel="Nova Solicitação"
            sx={{ position: 'fixed', bottom: 16, right: 16, color: 'black' }}          
            icon={<SpeedDialIcon color='success' />}
            onClick={() => handleNewRequest()}
        >
        </SpeedDial>
    </Box>
  )
}

export default RequestsFrequent;