import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './index.css';
import App from './App';

const client = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
    mutations: {
      onSuccess: ({ data }) => toast.success(data.message),
      onError: ({ response }) => toast.error(response.data.message),
    },
  },
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ToastContainer limit={2} position="top-right" autoClose={2000} />
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
