import  { useRef } from 'react';
import JoditEditor from 'jodit-react';
import {  Button, Checkbox,  FormControl, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PubReasonsRequest from '../pubReasonsRequests';
import { createPublicationFn, publicationFn, updatePublicationFn } from '../../../../services/helpdesk';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleInput from '../../../shared/SimpleInput/SimpleInput';
import CategoriesPubSelect from '../../categories/CategoriesPubSelect';
import SubCategoriesPubSelect from '../../categories/SubCategoriesPubSelect';
import { toast } from 'react-toastify';
import { useCryptoSessionStorage } from '../../../../hooks/useCryptoSessionStorage';

const CriarPub = (props) => {
  const location = useLocation();
  const pubId = location.state?.id;
  const { getData } = useCryptoSessionStorage();
  const user = getData("user") || {};
  const editor = useRef(null);
  const navigate = useNavigate();

  const { data: publication, isLoading } = useQuery(['publication', pubId], () => publicationFn({ id: pubId }));

  const { mutate: update, isLoading: isAdding } = useMutation(updatePublicationFn, {
    onSuccess: ({ data }) => {
      toast.success(data.message)
      navigate(-1)
      formik.resetForm()
    },
    onError: ({ response }) => toast.error(response.data.message),
  });

  const { mutate: create, isLoading: isCreating } = useMutation(createPublicationFn, {
    onSuccess: ({ data }) => {
      toast.success(data.message)
      navigate(-1)
      formik.resetForm()
    },
    onError: ({ response }) => toast.error(response.data.message),
  })

  const initialValues = {
    id: publication?.id || 0,
    title: publication?.title || '',
    description: publication?.description || '',
    content: publication?.content || '',
    admin: publication?.admin || 'N',
    id_category: publication?.id_category || '0',
    category: publication?.category || '',
    id_subcategory: publication?.id_subcategory || '0',
    subcategory: publication?.subcategory || '',
    user: user?.id || '',
    reasons: publication?.reasons || [],
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Informe o título da publicação'),
    description: Yup.string().required('Informe a descrição da publicação'),
    content: Yup.string().required('Informe o conteúdo da publicação'),
    id_category: Yup.string().required('Selecione uma categoria'),
    id_subcategory: Yup.string().required('Selecione uma subcategoria'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false, 
    validateOnBlur: true,  
    onSubmit: async (values) => { 
      publication?.id > 0 ? update(values) : create(values);
    },
  });

  const handleChangeAdmin = (event) => {
    formik.setFieldValue('admin', event.target.checked ? 'Y' : 'N');
  };

  const handleContentChange = (newContent) => {
    formik.setFieldValue('content', newContent);
  };

  const buttons = [
    'undo', 'redo', '|', 'bold', 'strikethrough', 'underline', 'italic', '|', 'superscript', 'subscript', '|', 'align',
    '|', 'ul', 'ol', 'outdent', 'indent', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'image', 'link', 'table',
    '|', 'hr', 'eraser', 'copyformat', '|', 'fullsize', 'selectall', 'print', '|', 'source', '|',
  ];

  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: 'en',
    toolbarButtonSize: 'medium',
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    theme: localStorage.getItem('theme'),
    buttons: buttons,
    width: '100%',
    height: 500,
  };

  const handleClose = () => {
      navigate(-1);
  }

  return (
    <Box sx={{ overflow: 'scroll', height: '100%', p: 2 }}>
      <Box open={props.open} disableEnforceFocus className='dialog-new-pub' sx={{ overflow: 'scroll', height: '100%' }}>        
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ m: 1 }}>
            <SimpleInput
              id="title" 
              label="Titulo da Publicação"
              type="text"
              placeholder="Informe o título da publicação"
              formik={formik}
            />
            <SimpleInput
              id="description" 
              label="Descrição da Publicação"
              type="text"
              placeholder="Informe a descrição da publicação"
              multiline
              formik={formik}
            />
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <CategoriesPubSelect formik={formik} name="id_category"  />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SubCategoriesPubSelect formik={formik} name="id_subcategory" />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl fullWidth>
                  <Typography>
                    <Checkbox
                      labelId="visibilidade-label"
                      label="Quem pode ver, todos?"
                      name="admin"
                      checked={formik.values.admin === 'Y'}
                      onChange={handleChangeAdmin}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    Somente Técnicos
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <PubReasonsRequest formik={formik} />
            <Box className="box-editor">
              <JoditEditor
                ref={editor}
                value={formik.values.content}
                config={editorConfig}
                onBlur={handleContentChange}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2, mb: 10 }}>
              <Button onClick={handleClose} variant="outlined" color="error">Cancelar</Button>
              <Button type="submit" variant="outlined" color="success">Salvar</Button>
            </Box>
          </Box>

        </form>
      </Box>
    </Box>
  );
};

export default CriarPub;