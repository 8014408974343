import SearchAndSelect from "../SearchAndSelect";
import { useQuery } from "react-query"
import { typeServicesFn } from "../../../services/typeServices";

const TypeServiceSelect = ({ formik, name, setValue, value, error, helpText }) => {
    const { data, isLoading } = useQuery("type-services-list", () => typeServicesFn());
    const services = data?.data?.map((i) => ({ label: i.description, value: i.id }));
    return <SearchAndSelect 
                name={name} 
                setValue={setValue} 
                value={value} 
                placeholder="Selecione uma opção" 
                label={"Tipo de Serviço"} 
                options={services || []} 
                formik={formik} 
                loading={isLoading} 
                fullWidth
                className="mb-2"
                error={error}
                helpText={helpText}
            />
};
  

export default TypeServiceSelect;