import axiosInstance from "../../config/axio.config"; 
const typeServices = '/requests/typeServices';

export const typeServicesFn = (reqBody) => {
    try {
      const response = axiosInstance.get(typeServices, { params: reqBody })
      return response
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};
  