import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, SpeedDial, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useCryptoSessionStorage } from '../../../../hooks/useCryptoSessionStorage';

let url = process.env.REACT_APP_API+ "/images/";


const Image = (props) => {  
    const { getData } = useCryptoSessionStorage();
    const user = getData("user") || {};
    const { editData, postData, deleteData } = useFetchData(url)
    const { ShowMessage } = useShowMessages();
    const [errTitle, setErrTitle] = useState(false);
    const [errorImage, setErrorImage] = useState(false);
    const [disabledButtons, setDisabledButtons] = useState(false);

    const handleChangeValues = (value) => {
        props.setImage((prevValues) => ({
        ...prevValues, 
        [value.target.name] : value.target.value,
        }));

    };

    const handleChageFile = (value) => {
        props.setImage((prevValues) => ({
            ...prevValues, 
            ['file'] : value,
            }));      
    }


    const handleChageImage = (value) => {
      props.setImage((prevValues) => ({
          ...prevValues, 
          ['path'] : value,
          }));      
    }

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleClickSend = async () => {

        if(!props.image.title){
          setErrTitle(true);
          return 
        }

        let res = {};
        let time = 0;



      try {
        if(props.editing){
          setDisabledButtons(true);
          res =  await editData(url, props.image);
        }else{
          if(!props.image.file){
            setErrorImage(true);
            handlerErrorImage();
            return 
          }
          setDisabledButtons(true);
          const formData = new FormData();
          formData.append('file', props.image.file);
          formData.append('title', props.image.title);
          formData.append('user', user?.id);
          res = await postData(url, formData);
          time = 1; // 1 segundo
        }

        if(res.success){
          ShowMessage('success', res.msg);
          setTimeout(() => {
            props.setChanged(!props.changed);
          }, time*1000);      
          handleClose();   
        }else{
          ShowMessage('error', res.msg);
        }
       }
       finally {
        setDisabledButtons(false);
       }

    }

    function getBase64(file) {
        return new Promise(function(resolve) {
          var reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result)
          }
          reader.readAsDataURL(file);
        })
      }

    const handleUploadClick = async(event) => {
        var file = event.target.files[0];
        var url = await getBase64(file);
        handleChageFile(file);
        handleChageImage(url);
      };

    const handleDelete = async() => {



        try {
          setDisabledButtons(true);
          const res =  await deleteData(url, {id: props.image.id}); 
          if(res.success){
              ShowMessage('success', res.msg);
              props.setChanged(true);
              handleClose();
          }else{
              ShowMessage('error', res.msg);
          }
        } finally {
          setDisabledButtons(false);
        }
    }

    const handleChangeTitle = (e) => {
      handleChangeValues(e);
      if(e.target.value.length > 1) {
        setErrTitle(false);
      }
    }

    const handlerErrorImage = () => {
      setTimeout(() => {
        setErrorImage(false);
      }, 2*1000); // 2 segundos
    }

  return (
    <Dialog open={props.open}>
        <DialogTitle >Cadastro de imagens</DialogTitle>
        <DialogContent>
          <Grid container spacing={1} columns={ 12 } sx={{float: "center",}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} marginTop={1}>
              <TextField
                className='label-MovtAtendimento-admin'
                autoFocus
                name="title"
                value={props.image.title}
                defaultValue=" "
                label="Título"
                type="text"
                helperText={(errTitle) ? "Informe o título da imagem." : ''}
                error={errTitle}
                onChange={handleChangeTitle}
                fullWidth
                variant="filled"
              />                              
            </Grid>
          </Grid>
            <Box  display={'flex'}
            sx={{
                width:{xs: "90%", sm: "90%", md: "80%", lg: "70%", xl: "550px"},
                height:{xs: "30%", sm: "60%", md: "60%", lg: "60%", xl: "300px"},
                justifyContent:"center", alignItems:"center", p: 2, maxHeight: '300px'
                }} 
                
            >
                <img style={{maxWidth: '90%', maxHeight: '90%',  width: 'auto', height: 'auto', align: 'middle'}} src={props.image.path} />
                
            <div>

            </div>  
            </Box>
          <Grid container justify="center" alignItems="center">
            <Box sx={{display: "none"}}>
            <input
              accept="image/*"             
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUploadClick}
            />
            </Box>
            

            <label htmlFor="contained-button-file" style={{display: (props.editing) ? 'none' : ''}}>
              <Fab component="span" sx={(errorImage ) ? {color: 'red', border: '1px solid', borderColor: 'red'} : {}}>
                <AddPhotoAlternateIcon />
              </Fab>
            </label>


          </Grid>
                
        </DialogContent>
        <DialogActions sx={{paddingRight: 3, }} >
            <Button disabled={disabledButtons} 
              onClick={handleDelete} 
              style={{display: (props.editing) ? '' : 'none', }} 
              variant="outlined" color="error"
              >
                Excluir
            </Button>
            <Button disabled={disabledButtons} onClick={handleClose} variant="outlined" color='error'>Cancelar</Button>
            <Button 
              disabled={disabledButtons} 
              onClick={() => handleClickSend()} 
              variant="outlined" 
              color="success"       
              >
                Salvar
            </Button>
        </DialogActions>
      </Dialog>

  )
}

export default Image