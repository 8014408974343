import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, {  useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';
import { useCryptoSessionStorage } from '../../../../hooks/useCryptoSessionStorage';

moment.tz.setDefault('America/Manaus');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');
const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
let url = process.env.REACT_APP_API+ "/requests/";
let urlUsers = process.env.REACT_APP_API+ "/user/all";


const rangeTimes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const RequestSelectUser = (props) => {
    const { getData } = useCryptoSessionStorage();
    const user = getData("user") || {};
    const [disabledButtons, setDisabledButtons] = useState(false);
    const { data : listUsers, editData } = useFetchData(urlUsers);
    const { ShowMessage } = useShowMessages();

    const handleChangeUser = (values) => {
        props.setValues((prevValues) => (
          {
              ...prevValues, "user": (!values) ? null : values.id,
        }));  
        props.setValues((prevValues) => (
          {
              ...prevValues, "nameUser": (!values) ? '' : values.name,
        }));      
    
      }

    const handleChangeValues = (value) => {  
        props.setValues((prevValues) => ({
          ...prevValues, 
          [value.target.name] : value.target.value,
        }));
    };

    const handlerChangeStartDate= (value) => {  
      props.setValues((prevValues) => ({
        ...prevValues, 
        'start_date' : value
      }));
    };

    const handleClose = () => {
      props.handleChanged();
      props.setOpen(false);
    }

      const sendValues = async (values) => {
        let request = values;
        
        if(!request.creator_user){
          request.creator_user = request.user
        };

        request.start_date = dayjs(request?.start_date).utc(true);
        
        editData(url, request).then((res) => {
            if(res.success){
              ShowMessage('success', 'Bom trabalho',  'Atendimento confirmado com sucesso!'); 
              props.handleChanged();
              handleClose();
            } else {
              ShowMessage('error', res.msg);
            }
          });
      }

      const handleClickSend = async () => {

        if(props.transfer){
            if(!props.values.user){
                ShowMessage('error', 'Preencha todos os campos', 'Informe o atendente.')    
                return;
            }
            if(!props.values.description){
                ShowMessage('error', 'Preencha todos os campos', 'Informe a abertura do atendimento.')    
                return;
            }
        }
        if(!props.values.start_date){
            ShowMessage('error', 'Preencha todos os campos', 'Informe a previsão de atendimento.')    
            return;
        }  

        sendValues(props.values);
      }

      const selectTime = (time) => {
        const now = dayjs.tz().format();
        const start_date = moment(now).add(time, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        handlerChangeStartDate(dayjs.tz(start_date));
      }

  return (
    <Dialog open={props.open} >
        <DialogTitle>
            {(props.transfer) ? 'Transferência de atendimento' : 'Confirmação de atendimento'}
        </DialogTitle>
        <DialogContent sx={{width: '500px'}}>                
            <Autocomplete
                      disablePortal
                      fullWidth
                      options={listUsers}
                      sx={{ mt: 2, display:(props.transfer) ? '' : 'none'}}
                      getOptionLabel={ (option) => option['name'] || option }    
                      value={props.values?.nameUser}
                      onChange={(event, values) => handleChangeUser(values)}
                      renderInput={(params) => 
                      <TextField
                        name="user"
                        {...params} label="Atendente" 
                      />}
            />
            <TextField sx={{ mt: 2, display:(props.transfer) ? '' : 'none'}}
                label="Abertura do Atendimento"
                multiline
                rows={4}
                fullWidth                
                defaultValue=""
                name='description'
                value={props?.values?.description}
                onChange={(e) => handleChangeValues(e)}
                placeholder='Informe a baixa do atendimento'
                variant="outlined"
              

            /> 
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
              <MobileDateTimePicker 
                fullWidth
                sx={{width: '100%', mt: 2}}
                label={'Previsão para Atendimento'} 
                ampm={false} 
                openTo="hours" 
                name="start_date"
                value={dayjs.tz(props.values?.start_date)}
                onChange={handlerChangeStartDate}
                format="DD/MM/YYYY HH:mm" 
              />
            </LocalizationProvider>   
            <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
            {rangeTimes.map((item) =>(
              <Chip 
                key={item}
                label={item} 
                variant="outlined" 
                onClick={(e) => {selectTime(item)}} 
              />   
            ))}
            </Box>                
        </DialogContent>
        <DialogActions>
        <Button disabled={disabledButtons} onClick={handleClose} variant="outlined" color='error'>Cancelar</Button>
            <Button 
              disabled={disabledButtons} 
              onClick={() => handleClickSend()} 
              variant="outlined" 
              color="success"       
              >
                Concluir
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default RequestSelectUser;