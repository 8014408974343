// React and Hooks
import React, { useEffect, useMemo, useState } from 'react';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useDebounce } from '../../../../hooks/useDebounce/useDebounce';

//Frameworks 
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import timezone from 'dayjs/plugin/timezone';
//Components
import RequestCard from '../../../../components/requests/cards/RequestCard';
import RequestAdmin from '../../../../components/requests/requestsModais/requestAdmin/requestAdmin';
import RequestConclude from '../../../../components/requests/requestsModais/requestConclude/requestConclude';
import RequestSelectUser from '../../../../components/requests/requestsModais/requestSelectUser/requestSelectUser';
import { RequestsHeader } from '../../../../components/requests/requestHeader/requestsHeader';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';

//Styles -- Mui
import { Badge, Box, Chip, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import './panelPage.css';
import RequestClient from '../../../../components/requests/requestsModais/requestClient/requestClient';
import RequestAddService from '../../../../components/requests/requestsModais/requestAddService/requestAddService';
import { useCryptoSessionStorage } from '../../../../hooks/useCryptoSessionStorage';


let url = process.env.REACT_APP_API+ "/requests/user";
let urlStatus = process.env.REACT_APP_API+ "/requests/status";

moment.tz.setDefault('America/Manaus');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

export default function RequestsPanelPage () {

  const { getData } = useCryptoSessionStorage();
  const user = getData("user") || {};
  const [request, setRequest] = useState({});
  const [openRequest, setOpenRequest] = useState(false);
  const [openRequestConclude, setOpenRequestConclude] = useState(false);
  const [openSelectUser, setOpenSelectUser ] = useState(false);
  const [openNewRequest, setOpenNewRequest] = useState(false);
  const [openAddService, SetOpenAddService] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [changed, setChanged ] = useState(false); 
  const { debounce } = useDebounce ();
  const typeFilter = sessionStorage.getItem('typeFilter');
  const userFilterId = sessionStorage.getItem('userFilterId');
  const userFilterName = sessionStorage.getItem('userFilterName');
  const groupFilterName = sessionStorage.getItem('groupFilterName');
  const groupFilterId = sessionStorage.getItem('groupFilterId');
  const orderBy = sessionStorage.getItem('requestsOrderBy');
  const priority = sessionStorage.getItem('requestsPriority');
  const ShowHiddenRequests = sessionStorage.getItem('show_hidden_requests');
  const { ShowMessage } = useShowMessages();

  const [filter, setFilter] = useState({
    id: userFilterId || user?.id,
    name: userFilterName || user?.name,
    nameGroup: groupFilterName || user?.description,
    idGroup: groupFilterId || user?.id_group,    
    type: (typeFilter) ? typeFilter : 'user',   
    order: (orderBy) ? orderBy : 's.created_at',
    priority: (priority) ? priority : 5,
    ShowHiddenRequests: (ShowHiddenRequests) ? ShowHiddenRequests : 'N',
  });


  const { data: requests, editData } = useFetchData(url, filter, changed, true, false, true);

  const handleChanged = () => {
    setChanged(!changed);
  }

  debounce((30*1000), () => {
    handleChanged();
  })

  const handleOpenNewRequest = () => {

    let newRequest = {
      creator_user: user?.id,
      user: (userFilterId) ? userFilterId : user?.id,
      sequence: 1,
      description: '',
      status: 1,
      type_service: 30,
      type_service_description: 'Acesso Remoto',
      type_request: 0,
      hide_panel: 'N',
      start_date: dayjs().utc(true).format()
    };



    setRequest(newRequest);
    setOpenNewRequest(true);
  }


  const newRequests = useMemo(() => {
    return requests && requests?.filter((item) => item.status ===  1);
  }, [requests]) 

  const requestsToDo = useMemo(() => {
    return requests && requests?.filter((item) => item.status ===  2);
  }, [requests]) 

  const  requestsDoing = useMemo(() => {
    return requests && requests?.filter((item) => item.status ===  3 || item.status ===  0);
  }, [requests]) 

  const  requestsDone = useMemo(() => {
    return requests && requests?.filter((item) => item.status ===  4);
  }, [requests]) 

  
  const removeCard = (origin, index) => {
    switch(origin){
      case '1': return newRequests.splice(index, 1);
      case '2': return requestsToDo.splice(index, 1);
      case '3': return requestsDoing.splice(index, 1);
      case '4': return requestsDone.splice(index, 1);
      default: return {};  
    }
  }

  const includeCard = (dest, index, card) => {
    switch(dest){
      case '1': return newRequests.splice(index, 0, card);
      case '2': return requestsToDo.splice(index, 0, card);
      case '3': return requestsDoing.splice(index, 0, card);
      case '4': return requestsDone.splice(index, 0, card);
      default: return {};  
    }
  }

  const handleListRequests = (status) => {
    switch (status) {
      case '1': return (newRequests && newRequests) ? newRequests : [{}];
      case '2': return (requestsToDo && requestsToDo) ? requestsToDo : [{}];
      case '3': return (requestsDoing && requestsDoing) ? requestsDoing : [{}];
      case '4': return (requestsDone && requestsDone) ? requestsDone : [{}];
      default: return [{}];     
    }
  }

  const statusGroups = [
    { id: '1', status: 1, title: 'Novas Solicitações', color: 'error', count: newRequests.length},
    { id: '2', status: 2, title: 'Aguardando', color: 'warning', count: requestsToDo.length},
    { id: '3', status: 3, title: 'Em andamento', color: 'info', count: requestsDoing.length},
    { id: '4', status: 4, title: 'Concluídas', color: 'success', count: requestsDone.length},
  ]

  const actions = [
    { icon: <PostAddIcon />, name: 'Novo Atendimento', action: handleOpenNewRequest },
  ];

  const handleConcludeRequest = () => {
    setOpenRequestConclude(true);
  }

  const handleConfirmRequest = () => {
    setTransfer(false);
    setOpenSelectUser(true);
  }

  const handleEditStatus = async (id, status, start_date) => {
    return await editData(urlStatus, {id, status, user: filter.id, start_date })
  }

  const handleOnDragEnd = (result) => {

    // Não permite voltar um atendimento para nova solicitação. 
    if (!result.destination || result.destination.droppableId === '1' || result.destination.droppableId === result.source.droppableId ) return;

    // Não permite concluir sem iniciar 
    if (result.destination.droppableId === '4' && result.source.droppableId != '3' ) {
       ShowMessage('error', 'Necessário iniciar o atendimento antes');
      return;
    };

    //Cria uma cópia do card que está sendo arrastado, e remove o mesmo coluna de origem, e insere na coluna de destino
    const card =  removeCard( result.source.droppableId, result.source.index, 1);
    includeCard(result.destination.droppableId, result.destination.index, card[0]);    

    const start_date = dayjs.tz().format();
    card[0].status = result.destination.droppableId;
    
    //Atualiza a hora de inicio/conclusão do atendimento 
    if(result.destination.droppableId === '3'){
      card[0].start_date = dayjs.tz(start_date).utc(true);
    } else if(result.destination.droppableId === '4'){
      if(user?.ignore_completion_time === 'Y'){
        card[0].conclusion_date = dayjs.tz(start_date).utc(true);
      } else {
        card[0].conclusion_date = null;
        card[0].start_date = null;
      }
    }

    if(result.destination.droppableId === '4'){
      setRequest(card[0]);
      handleConcludeRequest();
    } else 
    if(result.destination.droppableId === '2'){
      card[0].user = filter.id;
      setRequest(card[0]);
      if(!card[0]?.start_date){
        handleConfirmRequest();
      } else {
        handleEditStatus(result.draggableId, result.destination.droppableId);
      }

    } else {
      handleEditStatus(result.draggableId, result.destination.droppableId, dayjs(start_date).utc(true).format('YYYY-MM-DD HH:mm:ss'))
    }
    
  }

  return (
    <Box className="ServicesRequest" sx={{overflow: 'scroll', width: '100%', height: '100%'}} >
      <header className="App-header">
        <RequestsHeader values={filter} setValues={setFilter} handleChanged={handleChanged} />
      </header>
        <RequestClient open={openNewRequest} setOpen={setOpenNewRequest} admin={true} handleChanged={handleChanged} requestsPage={true} values={request} setValues={setRequest}/> 
        <RequestAdmin values={request} open={openRequest} setValues={setRequest} setOpenRequest={setOpenRequest} handleChanged={handleChanged}  />
        <RequestConclude values={request} open={openRequestConclude} setValues={setRequest} setOpen={setOpenRequestConclude} handleChanged={handleChanged}/>
        <RequestSelectUser values={request} open={openSelectUser} setValues={setRequest} setOpen={setOpenSelectUser} handleChanged={handleChanged} transfer={transfer}  />
        <RequestAddService values={request} open={openAddService} setOpen={SetOpenAddService} setValues={setRequest} handleChanged={handleChanged} />
        <DragDropContext className='requests-area' onDragEnd={handleOnDragEnd}>
          <div className='request-container-groups'  >
            {statusGroups.map((group) => {
              return (              
                <div className="statusGroups">
                  <div className="statusGroups-header" >
                  <Badge className='request-counts' badgeContent={group.count} color={group.color} >
                    <Chip  className='request-title' label={group.title} size='small' sx={{ '& .MuiChip-label': { fontSize: "0.8rem" }}}/>
                  </Badge>                   
                  </div>
                    <Droppable droppableId={group.id}> 
                    {(provided) => (
                      <div className='statusGroups-body'>
                        <ul className="itemRequest" {...provided.droppableProps} ref={provided.innerRef} >

                          {handleListRequests(group.id)?.map((ticket, index) => {
                            if(ticket?.service_id){
                              return (
                                <Draggable key={ticket?.service_id} draggableId={ticket?.service_id.toString()} index={index}>
                                  {(provided) => (
                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <RequestCard 
                                        admin={true}
                                        values={ticket} 
                                        setRequest={setRequest} 
                                        setOpenRequest={setOpenRequest} 
                                        handleConcludeRequest={handleConcludeRequest} 
                                        setOpenSelectUser={setOpenSelectUser}
                                        setTransfer={setTransfer}
                                        SetOpenAddService={SetOpenAddService}
                                        userFilter={userFilterId}
                                      />
                                    </li>
                                  )}
                                </Draggable>
                              );
                            }
                          })}  
                          <li className='droppable-areaEmpty'>
                          
                          </li>                     
                        </ul>
                        {provided.placeholder}
                      </div> 
                    )}
                    </Droppable>
                </div>                 
              )
            })}
          </div>            
        </DragDropContext>
      <Box sx={{  transform: 'translateZ(0px)', flexGrow: 1 }}>
        <SpeedDial
          ariaLabel="SpeedDial Actions"
          sx={{ position: 'fixed', bottom: 20, right: 8}}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      </Box>
    </Box>
  )
}
