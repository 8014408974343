import { TextField } from '@mui/material';

const SimpleInput = ({ id, label, type = "text", formik, ...rest }) => {
  
  const handleChange = (event) => {
    formik.setFieldValue(id, event.target.value);
  }

  return (
    <TextField
      {...rest}
      id={id}
      label={label}
      type={type}
      value={formik?.values?.[id]}
      error={formik?.errors?.[id] && formik?.touched?.[id] ? true : false}
      helperText={formik?.touched?.[id] && formik?.errors?.[id]}
      onBlur={formik?.handleBlur}
      onChange={formik?.handleChange}
      fullWidth
      variant="outlined"
      sx={{ marginBottom: 2 }}
    />
  );
};

export default SimpleInput;