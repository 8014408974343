import { getLegendPosition } from "../utils/stylesUtils";

export const getChartOptionsHorizontal = (option: number, orientation: string, animations: boolean, handleClickRequest: Function) => {

    return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 5,
        indexAxis: 'x',
        parsing: {
            xAxisKey: 'Data',
            yAxisKey: 'Total',
        },
        plugins: {
            legend: getLegendPosition(option, orientation),
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value: any) => {
                    return option === 3 ? value['Nome'] + ': ' + value['Total'] : value;
                },
                color: 'black',
                font: {
                    size: 14,
                },
            },
        },
        interaction: {
            mode: 'x',
        },
        onClick: (e: Event, i: Array<any>) => {
            if (i.length > 0 && (option === 3 || option === 4 || option === 5)) {
                handleClickRequest(i);
            }
        },
        animations: animations ? {
            tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: true,
            },
        } : {},
    };
};

export const optionsVertical = (option: number, orientation: string, animations: boolean, handleClickRequest: Function) => {

    return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        indexAxis: 'y',
        plugins: {
            legend: getLegendPosition(option, orientation),
        },
        interaction: {
            mode:  'y'
        },
        onClick: (e: Event, i: Array<any>) => {
            if( i.length > 0 && (option === 3 || option === 4 || option ===5)){
                handleClickRequest(i)
            }
        },
        animations: (animations) ? {
            tension: {
            duration: 1000,
            easing: 'linear',
            from: 1,
            to: 0,
            loop: true
            }
        } : {},
    };
};        
