import SearchAndSelect from "../../../shared/SearchAndSelect";
import { useQuery } from "react-query"
import { categoriesPubsFn } from "../../../../services/helpdesk";

const CategoriesPubSelect = ({ formik, name, setValue, value }) => {

    const { data, isLoading } = useQuery(['pub-categories'], () => categoriesPubsFn());
    const categories = data?.map((i) => ({ label: i.name, value: i.id }));
    return <SearchAndSelect 
                name={name} 
                setValue={setValue} 
                value={value} 
                placeholder="Selecione uma opção" 
                label={"Categoria"} 
                options={categories || []} 
                formik={formik} 
                loading={isLoading} 
                fullWidth
                className="mt-2 mb-2"
            />
};
  

export default CategoriesPubSelect;