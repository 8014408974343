import SearchAndSelect from "../SearchAndSelect";
import { useQuery } from "react-query"
import { reasonsRequestsFn } from "../../../services/reasonsRequest";

const ReasonsRequestSelect = ({ formik, name, setValue, value, error, helpText }) => {
    const { data, isLoading } = useQuery(["reasons-requests-list"], () => reasonsRequestsFn({}));
    const reasons = data?.map((i) => ({ label: i.description, value: i.id }));

    const handleSelectOption = async (id) => {
        const item = await data.find((i) => i?.id === id);
        setValue(item);
    }
    return <SearchAndSelect 
                name={name} 
                setValue={handleSelectOption} 
                value={value} 
                placeholder="Selecione uma opção" 
                label={"Tipo de Atendimento"} 
                options={reasons || []} 
                formik={formik} 
                loading={isLoading} 
                fullWidth
                className="mt-2 mb-2"
                error={error}
                helpText={helpText}
            />
};
  

export default ReasonsRequestSelect;