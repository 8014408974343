import { Autocomplete, FormHelperText } from "@mui/material";
import CustomInput from "../CustomInput";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { useState, useEffect } from "react";

const SearchAndSelect = ({
  options,
  label,
  formik,
  name,
  setValue,
  value,
  isRequired = false,
  className = "",
  loading = false,
  placeholder = "Search and Select ",
  disabled = false,
  fetchOptions,
  error = false,
  helpText
}) => {
  const [inputValue, setInputValue] = useState(""); 

  const handleChange = (_event, newValue) => {
    const selectedValue = newValue ? newValue.value : null;
    if (formik) {
      formik.setFieldValue(name, selectedValue);
    } else {
      setValue ? setValue(selectedValue) : toast.info("Provide setValue Function");
    }

    setInputValue(newValue ? newValue.label : "");
  };

  let selectedOption;

  if (formik) {
    selectedOption = options && options.find((option) => option.value === formik.values[name]);
  } else {
    selectedOption = options && options.find((option) => option.value === value);
  }

  useEffect(() => {
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
  }, [selectedOption]);

  const debouncedFetchOptions = debounce((newInputValue) => {
    if (fetchOptions) {
      fetchOptions(newInputValue);
    }
  }, 300);

  const handleOnInputChange = (_, newInputValue, reason) => {
    if (reason === "input") {
      setInputValue(newInputValue);
      debouncedFetchOptions(newInputValue);
    }
  };

  return (
    <Autocomplete
    className={className}
      disabled={disabled}
      onInputChange={handleOnInputChange} 
      inputValue={inputValue} 
      options={options || []}
      loading={loading}
      value={selectedOption || null}
      onChange={handleChange} 
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <>
          <CustomInput
            id={name}
            isRequired={isRequired}
            {...params}
            label={label}
            placeholder={placeholder ? placeholder : label}
            error={(formik?.errors?.[name] && formik?.touched?.[name]) || (error) ? true : false}
            helpText={(formik?.errors?.[name] && formik?.touched?.[name]) || (helpText && helpText)}
          />
          {/* {(formik?.touched?.[name] && formik?.errors?.[name] ) || (error && helpText) && (
            <FormHelperText className="!text-red-500 !mx-1">
              {(formik?.touched?.[name] && formik?.errors?.[name]) || (helpText && helpText)}
            </FormHelperText>
          )} */}
        </>
      )}
    />
  );
};

export default SearchAndSelect;
