import { Box, Chip, Collapse, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReasonsRequest from '../../../shared/ReasonsRequest'

const reasons = [
    {
        id: 1,
        name: 'Atendimento'
    },
    {
        id: 2,
        name: 'Atendimento 2 '
    },
    {
        id: 3,
        name: 'Atendimento 3'
    },
    {
        id: 4,
        name: 'Atendimento 4'
    },

]


const PubReasonsRequest = ({formik}) => {

    const handleClickOption = (item) => {
        item.new = true;
        let reasons = formik.values.reasons || [];
        const filter = reasons?.filter(reason => reason.id === item.id);

        if(filter.length === 0) {
            reasons.push(item);
            formik.setValues((prevValues) => ({...prevValues, 'reasons': reasons}));
        } else if(filter[0].exclude) {
            reasons.map((reason) => {
                if(reason.id === item.id) {
                    reason.exclude = false;
                }
            });
            formik.setValues((prevValues) => ({...prevValues, 'reasons': reasons}));
        }
    };

    const handleDelete = (index) => {
        let reasons = formik.values.reasons || [];
        reasons[index].exclude = true;
        formik.setFieldValue('reasons', reasons);
    }

  return (
    <div className="justify-items-center">
        <Typography variant="h8" sx={{}} >Tipos de Atendimento Relacionados</Typography>
        <Box className="border-solid border-2 border-sky-500 p-2 m-2 w-full mb-4 rounded-lg min-h-10">
            {
                formik?.values?.reasons && formik?.values?.reasons
                    .filter(reason => !reason.exclude) 
                    .map((reason, index) => (
                    <Chip
                        label={reason.description}
                        onDelete={() => handleDelete(index)}
                        key={reason.id}
                    />
                ))
            }

        </Box>
        <ReasonsRequest onClick={handleClickOption}/>
    </div>
  )
}

export default PubReasonsRequest