import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SystemSelect from '../../../shared/SystemSelect';
import { toast } from 'react-toastify';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

let url = process.env.REACT_APP_API+ "/requests";
const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
const RequestConclude = (props) => {
    const [disabledButtons, setDisabledButtons] = useState(false);
    const { editData } = useFetchData(url);
    const { ShowMessage } = useShowMessages();
    const [errors, setErrors] = useState({});

    const handleChangeErrors = (err, state) => {
      setErrors((prevErrors) => ({
          ...prevErrors,
          [err]: state
        }));
    };

    const handleChangeValues = (value) => {  
        handleChangeErrors([value.target.name], !value.target.value)
        props.setValues((prevValues) => ({
          ...prevValues, 
          [value.target.name] : value.target.value,
        }));
    };

    const handlerChangeDate= (value, field) => {  
      props.setValues((prevValues) => ({
        ...prevValues, 
        [field]: value
      }));
    };

    const handleClose = () => {
      props.handleChanged();
      props.setOpen(false);
    }

      const sendValues = async (values) => {
        

        values.start_date = dayjs.tz(props.values?.start_date);
        values.conclusion_date = dayjs.tz(props.values?.conclusion_date);
        
        values.start_date = dayjs(values?.start_date).utc(true);
        values.conclusion_date = dayjs(values?.conclusion_date).utc(true);
        
        editData(url, values).then((res) => {
            if(res.success){
              ShowMessage('success', 'Bom trabalho',  'Você finalizou seu atendimento com sucesso!'); 
              props.handleChanged();
              handleClose();
            } else {
              ShowMessage('error', res.msg);
            }
          });
      }

      const handleClickSend = async () => {

        if(!props.values.start_date){
          return toast.error('Preencha a data de abertura do atendimento.'); 
        }

        if(!props.values.system){
          return toast.error('Selecione o sistema.'); 
        }

        if(!props.values.conclusion){
          return toast.error('Informe a baixa do atendimento.');
        }
        if(!props.values.conclusion_date){
          return toast.error('Informe a data de conclusão.');
        }

        sendValues(props.values);
      }

      const handlerChangeSystem = (value) => {
        props.setValues((prevValues) => ({
          ...prevValues, 
          'system': value || null
        }));
        handleChangeErrors('system', !value);
      }

  return (
    <Dialog open={props.open}>
        <DialogTitle>
            Informe os dados da baixa.
        </DialogTitle>
        <DialogContent >    
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker 
                fullWidth
                sx={{width: '100%', mt: 1, mb: 1}}
                label={'Iniciado em:'} 
                ampm={false} 
                openTo="day" 
                name="start_date"
                value={dayjs.tz(props.values?.start_date)}
                onChange={(e) => handlerChangeDate(e, 'start_date')}
                format="DD/MM/YYYY HH:mm" 
              />
            </LocalizationProvider>                        
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                fullWidth
                sx={{width: '100%', mt: 1, mb: 1}}
                label={'Concluído em:'} 
                ampm={false} 
                openTo="day" 
                name="conclusion_date"
                value={dayjs.tz(props.values?.conclusion_date)}
                onChange={(e) => handlerChangeDate(e, 'conclusion_date')}
                format="DD/MM/YYYY HH:mm" 
                error={errors?.conclusion_date}
                helperText={errors?.conclusion_date && "Informe a data de conclusão"}
              />
            </LocalizationProvider>  
            <SystemSelect  
              value={props?.values?.system} 
              setValue={handlerChangeSystem} 
              name="system" 
              error={errors?.system}
              helpText={errors?.system && "Selecione o sistema"}
            />
            {/* <FormControl fullWidth sx={{mt: 1, mb: 1}}>
            <InputLabel id="tipo-atendimento-label">Tipo</InputLabel>
            <Select
                labelId="tipo-atendimento-label"
                label="Tipo"
                fullWidth
                name="type_request"
                value={props.values?.type_request} 
                onChange={(e) => handleChangeValues(e)}
            >
                <MenuItem value={0}><em>Automação</em></MenuItem>
                <MenuItem value={1}><em>Ponto</em></MenuItem>
                <MenuItem value={2}><em>Assistência</em></MenuItem>
                <MenuItem value={3}><em>Maker</em></MenuItem>
                <MenuItem value={4}><em>Site</em></MenuItem>                            
            </Select> 
          </FormControl> */}
            <TextField sx={{mt: 1}}
                label="Baixa do Atendimento"
                multiline
                fullWidth                
                name='conclusion'
                defaultValue={props?.values?.conclusion}
                onBlur={(e) => handleChangeValues(e)}
                placeholder='Informe a baixa do atendimento'
                variant="outlined" 
                error={errors?.conclusion}
                helperText={errors?.conclusion && "Informe a baixa do atendimento"}     
            /> 
        </DialogContent>
        <DialogActions>
        <Button disabled={disabledButtons} onClick={handleClose} variant="outlined" color='error'>Cancelar</Button>
            <Button 
              disabled={disabledButtons} 
              onClick={() => handleClickSend()} 
              variant="outlined" 
              color="success"       
              >
                Concluir
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default RequestConclude;