import CryptoJS from 'crypto-js';

let encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const useCryptoData = () => {

    function encryptData(data) {
        if(!data) return {};
        const jsonString = JSON.stringify(data);
        const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();
        return encrypted;
    }
    function decryptData(encryptedData) {
        if(!encryptedData) return;
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
        const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
        const permissionsTree = JSON.parse(decryptedString);
        return permissionsTree;
    }

    function hashKey(key){ 
        if (!key) return {}; 
        const hashed = CryptoJS.SHA256(key).toString(); 
        return hashed; 
    }

    return{encryptData, decryptData, hashKey }

};


