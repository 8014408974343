import axiosInstance from "../../config/axio.config"; 
const systems = '/systems';

export const systemsFn = (reqBody) => {
    try {
      const response = axiosInstance.get(systems, { params: reqBody })
      return response
    } catch ({ error }) {
      throw new Error(error?.message)
    }
  };
  