import { Box, Grid, Typography,  Stack, Pagination, Divider, Breadcrumbs, Dialog, DialogTitle, DialogContent, Autocomplete, TextField, Paper, Checkbox, DialogActions, Button, IconButton, Badge, SpeedDial, SpeedDialIcon } from '@mui/material'
import { useMemo, useState } from 'react'
import AppBarPrincipal from '../../../appBar/Appbar'
import CardPub from '../publicationCard/PublicationCard'
import './PublicationsPage.css'
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import PublicationViwenPage from '../publicationViewPage/PublicationViewPage';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import SearchBar from '../../../searchBar/SearchBar'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR } from '@mui/x-date-pickers/locales';
import FilterListIcon from '@mui/icons-material/FilterList';

let url = process.env.REACT_APP_API+'/helpdesk/publications';
let urlCategories = process.env.REACT_APP_API+ "/helpdesk/categories/all";
let urlSubcategories = process.env.REACT_APP_API+ "/helpdesk/subcategories";
let urlUsers = process.env.REACT_APP_API+ "/user/all"; 

const PublicationsPage = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams([]);
    const [textSearch, setTextSearch ] = useState('');
    const [changed, setChanged] = useState(false);
    const { data : subcategories } = useFetchData(urlSubcategories);
    const { data : categories } = useFetchData(urlCategories);
    const { data : listUsers } = useFetchData(urlUsers);
    const [openFilter, setOpenFilter] = useState(false);
    const [filters, setFilters] = useState({
        FilterDate: false,
        InitialDate: dayjs(), 
        FinalDate: dayjs(), 
        user: 0,
      });
    const [elevationDate, setElevationDate] = useState(0);

    const handleCloseFilter = () => {
        setOpenFilter(false);
    }

    const handleOpenFilter = () => {
        setOpenFilter(true);
    }

    const [category, setCategory] = useState({});
    const [subcategory, setSubcategory] = useState({});
    const handleCategory = (cat) => {
        setCategory(categories.find(e => e.id === cat));
    }
    const handleSubCategory = (sub) => {
        setSubcategory(subcategories.find(e => e.id === sub));
    }    

    const handleChangeParamCategory = () => {
        setSearchParams({  c: category?.id.toString() }, { replace: true })
        setChanged(!changed);    
    }

    const handleHome = () => {
        setChanged(!changed)
    }

    const breadcrumbs = [
        <Link className='breadcrumbs'  to={''} onClick={() => handleHome()} >
            Início {(category?.name) ? '❯'  : ''}
        </Link>,
        <h className='breadcrumbs' onClick={() => handleChangeParamCategory()}    
        >
            {category?.name} {(category?.name) ? '❯'  : ''}
        </h>,
            <Typography key="3" color="text.primary" >
                {subcategory?.name}
            </Typography>,
      ];


    // paginação
    const filter = useMemo(() => {
        return searchParams.get('filter') || '';
    }, [searchParams]);    

    const page = useMemo(() => {
        return Number(searchParams.get('page') || '1');
    }, [searchParams]);

    const pub = useMemo(() => {
        return (searchParams.get('pub') || '');
    }, [searchParams]);

    const cat = useMemo(() => {
        return (searchParams.get('c') || 0);
    }, [searchParams]);

    const reason = useMemo(() => {
        return (searchParams.get('reason') || 0);
    }, [searchParams]);


    const handleChangeParams = (event, value) => {
      setSearchParams({ filter, page: value?.toString() }, { replace: true })
      setChanged(!changed);
    };
  

    const { data : publications } = useFetchData(url, {
        admin: props.admin, 
        page: page-1, 
        filter: filter, 
        cat : cat, 
        reason: reason,
        FilterDate: filters?.FilterDate,
        InitialDate: filters?.InitialDate,
        FinalDate: filters?.FinalDate,
        user: filters?.user  
    }, changed);

    const handleSearch = () => {    
        setSearchParams({ filter: textSearch, page: '1' }, { replace: false });
        setChanged(!changed);
    }

    const countFilter = ()=>{

        let count = 0;
    
        if(filters.FilterDate){
          count = count + 1;
        }
        if(filters.user){
          count = count + 1;
        }    
        setFilters((prevValues) => (
          {...prevValues, 
            'count' : count}
        )); 
    }    
    
    
    const handleChangeUser = (values) => {
    setFilters((prevValues) => (
        {
            ...prevValues, "user": (!values) ? null : values.id,
    }));  
    setFilters((prevValues) => (
        {
            ...prevValues, "nameUser": (!values) ? '' : values.name,
    }));    
    }
    
    
    const handleChangeFiltersDate = (field, value) => {
    
    setFilters((prevValues) => (
        {...prevValues, 
        [field] : dayjs(value),}
    ));

    };

    const handleChangeDateChecked = (checked) => {
    setFilters((prevValues) => (
        {...prevValues, 
        'FilterDate' : checked,}
    ));
    (checked) ? setElevationDate(5) : setElevationDate(0)

    if(checked){
        setFilters((prevValues) => (
        {...prevValues, 
            'count' : 1}
        ));
    }
    }
    
    const applyFilter = () => {
        handleCloseFilter();
        countFilter();        
        setChanged(!changed);
    }

    const handleOpenPub = (pub, cat, sub) => {
        handleCategory(cat);
        handleSubCategory(sub);
        setSearchParams({ pub: pub.toString()},  { replace: false })
    }

    const HandleRenderPagePub = (theme) =>{
        return <PublicationViwenPage id={pub} />
    }

    const results = () => {
        if(publications?.length == 0){
            return 'Nenhuma publicações encontrada.. Verifique o filtro e tente novamente'
        } 
        return filter.length > 0 || cat.length > 0 || filters?.count > 0 ? publications[0].count + 
        ((publications[0].count == 1) ? ' publicação encontrada' : ' publicações encontradas' ) : 'Últimas Publicações'
    }

    const handleNewPub = () => {
        navigate(`/admin/pub`, { state: { pageTitle: 'Nova Publicação' } });
    }

  return (
    <Box  container className='div-helpdesk' sx={{overflow: 'auto'}} > 
        { (!props.admin) ? <AppBarPrincipal/> : ''}    
        <SearchBar title='Em que podemos ajudar?' 
            filter={textSearch} 
            setFilter={setTextSearch} 
            handleSearch={handleSearch}
            FilterButton={
                (
                    <IconButton type="button" sx={{ p: '10px' }} title='Filtros' onClick={handleOpenFilter}>
                        <Badge color="primary" variant="standard" badgeContent={filters?.count}>
                        <FilterListIcon />
                        </Badge>        
                    </IconButton> 
                )
            }
        
        
        />
        <Divider />
        <Stack spacing={1} alignItems="center" display={(!pub) ? 'none' : ''} sx={{m: 1}}>
            <Breadcrumbs
                separator={''}
                spacing={0}
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
        <Divider />
        <Box className="box-publications" display={(pub !== '') ? 'none' : ''}>
            <Box sx={{height: 'auto'}}>            
                <Grid container spacing={2} columns={1}  
                    sx={{width:{xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "50%"},  
                    paddingLeft: {xs: "5%", sm: "0%", md: "5%", lg: "10%", xl: "10%"}, mt: 2,}}>
                    <Typography variant='h4' gutterBottom>{results()}</Typography>
                    { publications && publications.map((pub) => (
                        <Grid key={pub.id} item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <CardPub 
                                values={pub} 
                                setComponent={props.setComponent}  
                                handleOpenPub={handleOpenPub} 
                                admin={props.admin}
                            />
                        </Grid>  
                    ))}
                </Grid>                             
            </Box>
            <div className='pagination-pubs'>
                <Stack spacing={2} display={(publications.length > 0) ? (publications[0].count > 10) ? '' : 'none' : 'none'}>
                    <Pagination 
                        count={(publications.length > 0) ? Math.ceil(publications[0].count/10) : 0} 
                        page={page} 
                        onChange={handleChangeParams} 
                        color="primary"
                    />
                </Stack>
            </div>
            <SpeedDial
                ariaLabel="Cadastro de publicação"
                sx={{ position: 'fixed', bottom: 16, right: 16, }}
                icon={<SpeedDialIcon  />}
                onClick={() => handleNewPub()}
            >
            </SpeedDial>
        </Box> 
        <Box className="box-view-pub" 
            display={(pub !== '') ? '' : 'none'}>
            {HandleRenderPagePub(props.theme)}
        </Box>  
        <Dialog open={openFilter} onClose={handleCloseFilter}> 
            <DialogTitle>Filtrar Atendimentos</DialogTitle>
            <DialogContent>
            <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <Autocomplete
                    disablePortal
                    fullWidth
                    options={listUsers}
                    sx={{mt: 1}}
                    getOptionLabel={ (option) => option['name'] || option }    
                    value={filters?.nameUser}
                    onChange={(event, values) => handleChangeUser(values)}
                    renderInput={(params) => 
                    <TextField
                        name="user"
                        {...params} label="Atendente" 
                    />}
                />          
                </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <Paper elevation={elevationDate} sx={{p: 1}}>
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DemoContainer components={['DatePicker']}>
                    <DatePicker 
                        label="Data Inicial" 
                        value={dayjs(filters.InitialDate)}
                        onChange={(newValue) => handleChangeFiltersDate('InitialDate', newValue)}
                        format="DD/MM/YYYY" 
                    />
                    <DatePicker 
                        label="Data Final" 
                        value={dayjs(filters.FinalDate)}
                        onChange={(newValue) => handleChangeFiltersDate('FinalDate', newValue)}
                        format="DD/MM/YYYY" 
                    />
                    <Checkbox
                    checked={filters?.FilterDate}
                    onChange={(e) => handleChangeDateChecked(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />                  
                    </DemoContainer>         
                </LocalizationProvider>
                </Paper> 
            </Grid>

            </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={applyFilter}>Aplicar</Button>
            </DialogActions>        
        </Dialog>                      
    </Box>
  )
}

export default PublicationsPage