import { useCryptoData } from "../useCryptoData/useCryptoData";

export const useCryptoSessionStorage = () => {

    const { encryptData, decryptData, hashKey } = useCryptoData();

    const saveData = (key, data) => {
        const keyEncrypted = hashKey(key);
        const dataEncrypted = encryptData(data);
        sessionStorage.setItem(keyEncrypted, dataEncrypted);
    };

    const getData = (key) => {
        const keyEncrypted = hashKey(key);
        const dataEncrypted = sessionStorage.getItem(keyEncrypted);
        const data = decryptData(dataEncrypted);
        return data;
    };
    

    return{saveData, getData}

};


