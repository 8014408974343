import './App.css';
import { RoutesDefault } from './routes/RoutesDefault';



function App() {
  return (
      <div className="App">
        <RoutesDefault/>      
      </div>
  );
}

export default App;
