
import React, {useState} from 'react';
import ClientSelectionModal from '../../components/administrative/clients/clientSelectionModal/clientSelectionModal';
import { useShowMessages } from '../useMessages/useMessages';
import { useCryptoSessionStorage } from '../useCryptoSessionStorage';

function useClientSelection(callback) {
    const { getData } = useCryptoSessionStorage();
    const client = getData("client") || {};
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(client);
    const { ShowMessage, msgConfirm } = useShowMessages();
    function openModal() {
      setIsModalOpen(true);
    }
  
    function closeModal() {
      setIsModalOpen(false);
    }
  
    function handleClientSelect(client) {
      setSelectedClient(client);
      callback(client);
      closeModal();
      if (client?.priority === 2) {
        ShowMessage('warning', 'Cliente Preferencial', 'Esse cliente deve ser atendido com preferência!', 10);
      }
    }
  
    const modal = (
      <ClientSelectionModal
        open={isModalOpen}
        onClose={closeModal}
        onSelect={handleClientSelect}
      />
    );
  
    return {selectedClient, openModal, modal, setSelectedClient};
}

export default useClientSelection;