import axiosInstance from "../../config/axio.config"; 
const helpdesk = '/helpdesk';
const publications = '/helpdesk/publications';
const categories =  "/helpdesk/categories/all";
const subCategories =  "/helpdesk/subcategories";


export const publicationFn = async (reqBody) => {
    try {

      const response = await axiosInstance.get(publications+'/pub', { params: reqBody })
      return response.data[0];
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};

export const publicationsByReasonFn = async (reqBody) => {

    if(!reqBody.id) return [];
    try {
      const response = await axiosInstance.get(publications+'/reason', { params: reqBody })
      return response.data;
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};
  
export const categoriesPubsFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(categories, { params: reqBody })
      return response?.data || []
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};

export const subCategoriesPubsFn = async (reqBody) => {
    try {
      const response = await axiosInstance.get(subCategories, { params: reqBody })
      return response?.data || []
    } catch ({ error }) {
      throw new Error(error?.message)
    }
};
 
export const updatePublicationFn = (reqBody) => {
  try {
    const response = axiosInstance.put(publications, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error?.message)
  }
};

export const createPublicationFn = (reqBody) => {
  try {
    const response = axiosInstance.post(publications, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error?.message)
  }
}