import { useState } from 'react'
import { reasonsRequestsFn } from '../../../services/reasonsRequest';
import { Box, Chip, Collapse, IconButton, InputBase, Paper, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import SearchIcon from '@mui/icons-material/Search';

const ReasonsRequest = (props) => {

    const [filter, setFilter] = useState('');
    const [expandRequests, setExpandRequests] = useState(false);

    const { data: reasonsList, isLoading } = useQuery(["reasons-requests-list"], () => reasonsRequestsFn({}));

    const handleSelectOption = (item) => {
        props.onClick(item);
        setFilter('');
    }

  return (
    <Box                   
        onFocus={() => setExpandRequests(true)}
        onBlur={() => setExpandRequests(false)} 
        sx={{width: '100%', mb: 2}}
    >
    <Box  
        sx={{ 
                bgcolor: 'background.paper', 
                borderRadius: '12px', 
                boxShadow: 1,
                width: '100%', 
                p: '2px 4px', 
                display: 'flex',  
                mb: 2, 
                border: '1px solid rgba(0, 0, 0, 0)', 
                ':hover': { border: '1px solid' } 
            }}
        

    >
        <InputBase
            sx={{ ml: 1, flex: 1, width: '100%' }}
            placeholder="Filtrar tipos de atendimento"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
        />
        <IconButton type="button" sx={{ p: '10px' }} title='Buscar' >
            <SearchIcon />
        </IconButton>
    </Box >
    <Collapse in={expandRequests} timeout="auto" unmountOnExit sx={{ textAlign: "left", }}>
        <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
            {reasonsList && reasonsList?.filter((i) => i?.description?.toLowerCase().includes(filter?.toLowerCase())).slice(0, 9).map((item) =>(
                <Chip 
                key={item.id}
                    label={item?.description} 
                    variant="outlined" 
                    onClick={() => {handleSelectOption(item)}} 
                />   
            ))}
        </Box>
    </Collapse>  
    </Box>
  )
}

export default ReasonsRequest;