import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { ChartOptions as ChartJSOptions, ChartData } from 'chart.js';

// Defina a interface dos props
interface ChartOptionsProps {
  typeChart: number; // 1 para Bar, 2 para Line
  options: any; // Aceita as opções específicas do gráfico
  data: any; // Aceita os dados específicos do gráfico
}

// Componente ChartOptions
export const ChartOptions: React.FC<ChartOptionsProps> = ({ typeChart, options, data }) => {

  if(!options || !data || !data?.labels || !data?.datasets) return <div>Dados não encontrados, verifique os filtros...</div>;
  switch (typeChart) {
    case 1:
      return <Bar options={options} data={data} />;
    case 2:
      return <Line options={options} data={data} />;
    default:
      return <div>Invalid chart type</div>;
  }
};
