import SearchAndSelect from "../../../shared/SearchAndSelect";
import { useQuery } from "react-query"
import { categoriesPubsFn, subCategoriesPubsFn } from "../../../../services/helpdesk";
import { useMemo } from "react";

const SubCategoriesPubSelect = ({ formik, name, setValue, value }) => {

    const { data, isLoading } = useQuery(['pub-subcategories'], () => subCategoriesPubsFn());

    const subCategories = useMemo(() => {
        const filtered = data?.filter((c) => c.category === formik.values.id_category) || [];
        return filtered.map((i) => ({ label: i.name, value: i.id }));
    }, [data, formik.values.id_category]);

    return <SearchAndSelect 
                name={name} 
                setValue={setValue} 
                value={value} 
                placeholder="Selecione uma opção" 
                label={"Subcategoria"} 
                options={subCategories || []} 
                formik={formik} 
                loading={isLoading} 
                fullWidth
                className="mt-2 mb-2"
            />
};
  

export default SubCategoriesPubSelect;