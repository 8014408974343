import { Box, Chip } from '@mui/material'
import { useQuery } from 'react-query';
import { publicationsByReasonFn } from '../../../../services/helpdesk';

const ChipPubsByReason = (props) => {

    const { data: publications } = useQuery(["publications-by-reason", props?.id_reason], () => publicationsByReasonFn({ id: props?.id_reason }));
    const openPublications = () => {
        window.open(`/admin/h?reason=${props?.id_reason}`, "_blank")
    };

  if(!props?.id_reason || !publications) return null;

  return (
    <Box>
        <Chip 
            label={`Visualizar materias relacionados (${publications?.length || 0})`} 
            color="success" variant="outlined"
            onClick={() => openPublications()}
        />
    </Box>
  )
}

export default ChipPubsByReason