import { useNavigate } from 'react-router-dom';
import { useCryptoSessionStorage } from '../useCryptoSessionStorage';

let axios = require('axios');
let url = process.env.REACT_APP_API;


export const useAuthUser = () => {

  const { saveData } = useCryptoSessionStorage();
  const navigate = useNavigate();

  const handleSetLocalStorage = (res) => {
    saveData('user', res.user);
    saveData('userToken', res.token);
    saveData('userTokenAtt', res.tokenAtt);
  }


  const signIn = async (user, password) => {
    return await axios.get(url + "/user/login/", {
      params: {
        user: user,
        password: password
      }
    })
    .then(function (response) {
      handleSetLocalStorage(response.data)
      return {
              Authenticated : true,
              msg: response.data.message
            } 
    })
    .catch(function (error) {

      if (error.response) {
        return {
          Authenticated : false,
          msg: error.response.data.message
        } 
      }
    })
  };

  const signOut = () => {
    sessionStorage.clear();
    navigate("/"); 
  };

  const verifyPassword = async (user, password) => {

    return await axios.get(url + "/user/verifyPassword/", {
      params: {
        user: user,
        password: password
      }
    })
    .then(function (response) {
      return { Authenticated : true, msg: response.data.message} 
    })
    .catch(function (error) {
      if (error.response) {
        return {
          Authenticated : false,
          msg: error.response.data.message
        } 
      }
    })
  };

  return { signIn, signOut, verifyPassword }

}
