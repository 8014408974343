
import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, IconButton,  InputBase,  Modal,  Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


//hooks 
import {useFetchData} from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { useAuthUser } from '../../../../hooks/useAuthUser/useAuthUser';
import { useCryptoSessionStorage } from '../../../../hooks/useCryptoSessionStorage';

let url = process.env.REACT_APP_API+ "/client/all";

let blockDays = process.env.REACT_APP_DIAS_BLOQUEIO_CLIENTES;

const ClientSelectionModal = (props) => {

    const { getData } = useCryptoSessionStorage();
    const user = getData("user") || {};
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState('');
    const [changed, setChanged] = useState(false);
    const { ShowMessage, msgPassword } = useShowMessages();
    const { verifyPassword } = useAuthUser();
    
    const { data : listClients} = useFetchData(url, {filter: filter, page: page, rowsPerPage: rowsPerPage, listMaquinas: true}, changed);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      setChanged(!changed);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setChanged(!changed)
 
    };

    const getMsgStatus = (status) => {
      switch (status) {
        case '2 - CANCELADO': return 'Cliente com cadastro cancelado!';
        case '3 - SUSPENSO': return 'Cliente com cadastro suspenso!';
        default: return 'Cliente com status inválido!';
      }
    }

    const daysWithoutBackup = (lastBackupDate) => {
      const currentDate = new Date();
      const dateMinus5Days = new Date(currentDate.setDate(currentDate.getDate() - 5));
    
      return lastBackupDate < dateMinus5Days;
    };
    
    const handleSelectClient = async (client) =>{

      client.requestReleased = null;

         if (client?.show_alert === 'Y' && client?.alert_msg !== '') {
          await ShowMessage('warning', 'Aviso!', client?.alert_msg, 10);
         }

         if (client?.data_last_bkp !== null) {
          const lastBackupDate = new Date(client.data_last_bkp);
          const currentDate = new Date();
          
          if (daysWithoutBackup(lastBackupDate)) {
            const diffTime = Math.abs(currentDate - lastBackupDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            await ShowMessage('warning', 'Aviso!', `O último backup em nuvem do cliente foi há ${diffDays} dias!`, 10);
          }
        } else {
           await ShowMessage('warning', 'Configuração de Backup', 'Esse cliente ainda não possui o novo gerenciador de backup configurado!', 10);
        }

         if(client?.status === '2 - CANCELADO' || client?.status === '3 - SUSPENSO'){
          if(user.requests_release_client_suspended === 'Y'){
            return msgPassword('Cliente suspenso', 'Informe sua senha para realizar a liberação do atendimento!',
              'Liberar Atendimento', 'Cancelar',  user?.user, verifyPassword, () => {
                client.requestReleased = 'X';
                props.onSelect(client);
            }); 
          } else {
            return ShowMessage('warning', 'Atendimentos bloqueados', getMsgStatus(client?.status), 10);
          }
        };

         if (client?.hour_technical === 'Y') {
          if(user.requests_release_client_technical_hour === 'Y'){
            return msgPassword('Cliente hora técnica', 'Informe sua senha para realizar a liberação do atendimento!',
              'Liberar Atendimento', 'Cancelar',  user?.user, verifyPassword, () => {
                client.requestReleased = 'X';
                props.onSelect(client);
            })
          }else {
            return ShowMessage('warning', 'Cliente hora técnica', 'Solicite a liberação do atendimento junto ao setor financeiro', 10);
          }  
        } else if(client?.status === '4 - MAQUINAS'){
          if(user.requests_release_client_technical_hour === 'Y'){
            return msgPassword('Cliente máquinas', 'Informe sua senha para realizar a liberação do atendimento!',
              'Liberar Atendimento', 'Cancelar',  user?.user, verifyPassword, () => {
                client.requestReleased = 'X';
                props.onSelect(client);
            });            
         }else {
           return ShowMessage('warning', 'Cliente somente máquinas', 'Solicite a liberação do atendimento junto ao setor financeiro', 10);
         } 
        } else if(!client?.data_venc){
            props.onSelect(client);
        } else {
          let today = new Date(); 
          const diffInMs   = new Date(today) - new Date(client?.data_venc);
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  
          if( diffInDays >  blockDays){
            if(user.requests_release_client_defaulter === 'Y'){
              return msgPassword('Atendimentos bloqueados', 'Cliente inadimplente há ' +  Math.round(diffInDays) +  ' dias. Informe sua senha para realizar a liberação do atendimento!',
                'Liberar Atendimento', 'Cancelar',  user?.user, verifyPassword, () => {
                  client.requestReleased = 'X';
                  props.onSelect(client);
              });   
            } else {    
              return ShowMessage('warning', 'Atendimentos bloqueados', 'Cliente inadimplente há ' + Math.round(diffInDays) +  ' dias. Solicite a liberação do atendimento junto ao setor financeiro', 10);
            }
          } else {
            props.onSelect(client);
          }  
        }    
    }
 
    const handleSetFilter = (filter) => {
        setFilter(filter.target.value);
    }

    const handleSearch = () => {
        setChanged(!changed);
    }

    
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width:{xs: "100%", sm: "90%", md: "80%", lg: "800px"},
      borderRadius: '10px',
    };

    return (
      <Modal  open={props.open} onClose={props.onClose} disableAutoFocus={true} disableEnforceFocus={true}>
        <Box sx={style} >
          <Paper sx={{p: 2, borderRadius: '12px'}}>
          <h2>Listagem de Clientes</h2>
          <Box sx={{pt: 2}}>
            <Paper elevation={3} sx={{ p: '2px 4px', display: 'flex',  mb: 2, border: '1px solid rgba(0, 0, 0, 0)', ':hover': { border: '1px solid' } }}>
              <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Busque por Nome Fantasia, Razão Social e CNPJ/CPF"
                  value={filter}
                  onChange={handleSetFilter}
                  onKeyDown={(e) => {if(e.key === "Enter")handleSearch()}}
              />
              <IconButton type="button" sx={{ p: '10px' }} title='Buscar' onClick={handleSearch}>
                  <SearchIcon />
              </IconButton>
            </Paper >
            <Paper elevation={3} >
              <TableContainer  sx={{ overflow: 'scroll', height: '400px'}}>
              <Table  sx={{ width: '100%'}}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>  
                    <TableCell sx={{width: '50%'}}>Nome Fantasia</TableCell>
                    <TableCell sx={{width: '50%'}}>Razão Social</TableCell>     
                    <TableCell>Ação</TableCell>                                              
                  </TableRow>
                </TableHead>
                  <TableBody>
                  {
                  listClients && listClients.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">{row.id_gerente}</TableCell>
                      <TableCell >{row.nome_fantasia}</TableCell>
                      <TableCell align="left">{row.razao_social}</TableCell>
                      <TableCell align="center">
                          <Tooltip title='Selecionar' color='success' >
                              <IconButton onClick={() => handleSelectClient(row)} >
                                  <CheckCircleOutlineIcon />
                              </IconButton>
                          </Tooltip>   
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
                <Typography fullwidth variant='h4' align='center' 
                  sx={{width: '100%', color: 'red', mt: 7,
                  display: (listClients?.length > 0) ? 'none' : '', }} 
                >
                  Nenhum Registro Localizado
                </Typography>
              </TableContainer>           
              <TablePagination
                labelRowsPerPage='Registros por página'
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={(listClients) ? Math.ceil(listClients[0]?.count) : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />             
            </Paper>
          </Box>
          </Paper>
        </Box>
      </Modal>
    );
}

export default ClientSelectionModal