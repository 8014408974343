import React, {useState, useMemo, useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useFetchData } from '../../../hooks/useFetchData/useFetchData';
import dayjs from 'dayjs';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DatePicker, LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import './ChartsPage.css';
import RequestsTableModalCharts from '../../../components/requests/requestsTable/requestsTableModalCharts';
import RequestAdmin from '../../../components/requests/requestsModais/requestAdmin/requestAdmin';
import Loading from '../../../components/loading/loading';

//Types 
import { dataType } from '../types/types';
//Utils
import { months } from '../utils/dateUtils';

//styles
import { chartContainerBodyH, chartContainerBodyV } from '../styles/styles';
import { getHeightChart } from '../utils/stylesUtils';
import { ChartOptions } from '../components/ChartOptions';
import { getChartOptionsHorizontal, optionsVertical } from '../config/chartOptions';
import useFiltersStore from '../store/filtersStore';

const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
let url = process.env.REACT_APP_API+ "/charts";
let urlUsers = process.env.REACT_APP_API+ "/user/all";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    ChartDataLabels,
);

const RequestsChartsPage = () => {
    const [data, setData] = useState<dataType>({labels: [], datasets: []});
    const [totalRequests, setTotalRequests] = useState(0);
    const [filtersValues, setFiltersValues] = useState<any[]>([]); //Acessar os atendimentos clicando no gráfico
    const [animations, setAnimations] = useState(false);
    const [changed, setChanged] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);
    const [requestsList, setRequestsList] = useState<any[]>([]);
    const [labelsCount, setLabelCount] = useState(0);
    const [requestsClick, setRequestsClick] = useState({});
    const [openRequest, setOpenRequest] = useState(false);
    const [values, setValues] = useState({});
    const [requestsClickOpen, setRequestsClickOpen] = useState(false);
    const filters = useFiltersStore(state => state);

    const getTitleChart = () => {
        switch (filters?.option) {
            case 1: return 'Atendimentos Geral por tipo - Mês/Ano';
            case 2: return 'Atendimentos Geral - Período';
            case 3: return 'Atendimentos por Usuário - Dia a Dia';
            case 4: return 'Total de Atendimentos - Período - Ord. Nome';
            case 5: return 'Total de Atendimentos - Período - Ord. Quantidade';
            default:
                break;
        }
    } 

    const {  getDataDirectly, loading } = useFetchData();
    
    const handleChanged = () => {
        setChanged(!changed);
    }

    const handleCloseConfig = () => {
        setOpenConfig(false);
      }
    
      const handleOpenFilter = () => {
        setOpenConfig(true);
    }

    const { data : listUsers, editData } = useFetchData(urlUsers);

    const handleChangeFilters = (value: any) => {
        useFiltersStore.setState((prevState) => ({
          ...prevState,
          [value.target.name]: value.target.value,
        }));
      
        if (value.target.name === 'option' && (value.target.value === 4 || value.target.value === 5)) {
          handleChangeOrientation('y');
        } else {
          handleChangeOrientation('x');
        }
    };
      
    const handleChangeOrientation = (value: 'x' | 'y') => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            orientation: value,
        }));
    };


    const handleChangeFiltersDate = (field: string, value: any) => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            [field]: dayjs(value),
        }));
    };

    const handleChangeUser = (values: any) => {
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            ['user']: (!values) ? null : values.id,
        }));
        useFiltersStore.setState((prevState) => ({
            ...prevState,
            ['userName']: (!values) ? '' : values.name,
        }));   
    }
    
    const getDaysMonth = (year: number, month: number) => {
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);
        const numberDays = endDate.getDate();
        
        const dias = [];
        for (let day = 1; day <= numberDays; day++) {
            const diaFormatado = day.toString().padStart(2, '0');
            dias.push(diaFormatado);
        }
        
        return dias;
    };

    const getDays = () => {
        const startDate = new Date(filters?.InitialDate);
        const endDate =   new Date(filters?.FinalDate);
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

        const dias = [];
        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            const formattedDay = {
                dayFormat: dayjs(day).format('DD/MM'),
                day: dayjs(day)
            }
            dias.push(formattedDay);
        }        
        return dias;
    }    
    
    function filterRequestsByDay(requests: any[], date?: any, nameUser?: string, Type?: number) {
        return requests && requests?.filter(requests => {
            switch (filters?.option) {
                case 1: return requests?.day === date;
                case 2: return requests?.day === date && requests?.type_request === Type;    
                case 3: return requests?.nameUser === nameUser && requests?.conclusion_day === dayjs(date).format('DD/MM/YYYY');
                case 5: return requests?.conclusion_day === dayjs(date).format('DD/MM/YYYY');
                default:
                    break;
            }
        }).length;
    };
    
    function filterRequestsByUsers(requests: any[], nameUser: string) {
        return requests && requests?.filter(requests => {
            return requests?.nameUser === nameUser;
        }).length;
    };

    function filterRequestsByClient(requests: any[], codClient: string) {
        return requests && requests?.filter(requests => {
            return requests?.client === codClient;
        }).length;
    }

    const getRequestsUsers = (requests: any[]) => {
     
        const usersRequests = requests && requests.map((request) => {
            return request?.nameUser
        });

        const usersUnique =  [...new Set(usersRequests)];

        return usersUnique;
    };

    const getRequestsClients = (requests: any[]) => {
     
        const clientsRequests = requests && requests.map((request) => {
            return request?.client
        });

        const clientsUnique =  [...new Set(clientsRequests)];

        return clientsUnique;
    }

    const getNamesClients = (clients: any[], requests: any[]) => {
        
        const namesClients = clients && clients.map((client) => {
            return requests.filter((request) => request?.client === client)[0]?.nome_fantasia;
        });

        return namesClients;
    }

    const getLabelsData = (): any[] => {
        switch (filters?.option) {
            case 1: return getDaysMonth(filters?.year, filters?.month); 
            case 2: return getDaysMonth(filters?.year, filters?.month);
            case 3: return getDays();   
            case 4: return getDays();   
            case 5: return getDays();     
            case 6: return getDays();          
            case 7: return getDays();           
            default:
                return [];
        }
    };

    const handleOpenRequests = () => {
        setRequestsClickOpen(true);
    };

    const handleClickRequest = async (i: any) => {
        const date: any = filtersValues[i[0].index];
        const user = data.datasets[i[0].datasetIndex].label;
        const userLabel = data.labels[i[0].index];
        const setRequests = () => {
            return new Promise((resolve, reject) => {
                let requests = {};
                switch (filters?.option) {
                    case 3: {
                       requests = requestsList.filter((r) => {
                            return r?.nameUser === user && r?.conclusion_day === dayjs(date.day).format('DD/MM/YYYY');
                       });
                       break;
                    };
                    case 4: {
                        requests = requestsList.filter((r) => {
                             return  r?.nameUser === userLabel;
                        });
                        break;
                     };      
                    default:
                        break;
                }

                resolve(setRequestsClick(requests));
            })
        };

        await setRequests();
        handleOpenRequests();
    }

    const updateFilter = async() => {
        setData({
            labels: [],
            datasets: []
        });
        setTotalRequests(0);
        await getDataDirectly(url, {
            chart: true,
            year: filters?.year,
            month: filters?.month,
            user: (filters?.option === 4 || filters?.option === 5) ? 0 : filters?.user,
            option: filters?.option,
            FilterDate: filters?.option > 1,
            InitialDate: dayjs(filters?.InitialDate).utc(true).format('YYYY-MM-DD'),
            FinalDate: dayjs(filters?.FinalDate).utc(true).format('YYYY-MM-DD'),
            order: (filters?.option === 5 || filters?.option === 3 || filters?.option === 7) ? 'total' : 'name', 
            orderChart: filters?.option === 3 ?  filters?.orderChart : 'desc'
        },
        async (response: any) => {
            console.log(response);
            setData(response?.chartData);
            setTotalRequests(response?.totalRequests);
            setFiltersValues(response?.filters);
            handleCloseConfig();
            handleChanged();
            // setRequestsList(response);
            // await getDataChart(response).then(() => {
            //     handleCloseConfig();
            //     handleChanged();
            // });   
        }
        )
    }

    const handleChangeOrderChart = async (e: any) => {
        handleChangeFilters(e);
    }

    useEffect(() => {
        if (filters) {
            updateFilter();
        }
    }, [filters?.orderChart]);

    const getLegendPosition = () => {

        let position = 'top';
        let align = 'center';

        if(filters?.option > 2 || filters?.orientation === 'y'){
            align = 'start';
        }
        return {
            display: true,
            position: position,
            align: align
        }
    }; 
      

    const getChartByTypeHorizontal = () => {
        return <ChartOptions typeChart={filters?.typeChart} options={getChartOptionsHorizontal(filters?.option, filters?.orientation, animations, handleClickRequest)} data={data} />
    };

    const getChartByTypeVertical = () => {
        return <ChartOptions typeChart={filters?.typeChart} options={optionsVertical(filters?.option, filters?.orientation, animations, handleClickRequest)} data={data} />
    };

    const LabelFilter  = useMemo(() => {
        if(filters?.option === 1 || filters?.option === 2 ){
            return (filters?.month) ? `${months[filters?.month -1].name}, ${filters?.year}` : 'Todos';
        } else {
            return `${dayjs(filters?.InitialDate).format('DD/MM/YYYY')} a ${dayjs(filters?.FinalDate).format('DD/MM/YYYY')}`
        }
    }, [changed]);  

    const ChartHorizontal = useMemo(() => {
        return getChartByTypeHorizontal();
    }, [changed]);  

    const ChartVertical  = useMemo(() => {
        return getChartByTypeVertical();
    }, [changed]);  


    if(window.innerWidth < 500){
        return (
            <Box sx={{width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', mt: 20, userSelect: 'none',}}>
            <Box className="header-atendimento">
              <Typography variant="h4"  color="text.secondary" >
                Dispositivo incompatível.
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Acesse o Sirrus Atendimento do seu computador para visualizar os gráficos disponíveis.
              </Typography>
            </Box>
              <div >
                <img width={'100%'}  src="https://gifs.eco.br/wp-content/uploads/2022/10/gifs-de-graficos-0.gif" alt="Aguarde" />
              </div>
            </Box>
        )
    } else {
    return (    
        <Box sx={{height: '100%', width: '100%', p: 1, overflow: {xs: "scroll", sm: "scroll", md: "hidden", lg: "hidden"} }}> 
            <Box className="" sx={{ p: '2px 4px', display: 'flex',  mb: 2,  }}>
                <Grid container spacing={1} columns={ 12 } sx={{float: "center", width: '100%',}} >
                    {/* <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ }} >
                        <Box sx={{ ml: 1, width: '100%'  }}>
                            <Typography variant="h4" sx={{ fontSize: 25 }}  color="text.secondary" >
                                Gráficos de Atendimentos
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Realize a analise dos atendimentos realizados através dos gráficos disponíveis.         
                            </Typography>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={9} lg={5} xl={5} sx={{mt: 1, }} > 
                        <Paper elevation={3} sx={{width: '100%', p: 1, fontSize: 12}} >
                            <Typography >Gráfico: {getTitleChart()}</Typography>
                            <Typography>Período: {LabelFilter}</Typography> 
                            <Typography>Usuário: {(filters?.user) ? filters?.userName : 'Todos'}</Typography> 
                            <Typography>Total: {totalRequests}</Typography> 
                        </Paper>
                    </Grid>  
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{mt: 1, mb: 1}} >
                        <Box sx={{           
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%', 
                                    flexDirection: 'row'
                                }}>
                            <Button
                                variant='outlined'
                                onClick={handleOpenFilter}
                                endIcon={<SettingsIcon />}
                                sx={{ mr: 1,   p: '10px' }}         
                            >   
                                Opções 
                            </Button>
                            <FormControl  sx={{mr: 1, display: (filters?.option === 3) ? 'flex' : 'none'}}>
                                <InputLabel id="orderChart">Ordem</InputLabel>
                                <Select
                                    labelId="orderChart"
                                    label="Ordem"
                                    fullWidth 
                                    name='orderChart'
                                    value={filters?.orderChart} 
                                    onChange={handleChangeOrderChart}
                                >
                                    <MenuItem value={'asc'}><em>Crescente</em></MenuItem>
                                    <MenuItem value={'desc'}><em>Decrescente </em></MenuItem>
                                </Select>
                            </FormControl>                             
                            {/* <Button
                                variant='outlined'
                                onClick={handlerChanged}
                                endIcon={<PublishedWithChangesIcon />}
                                sx={{ mr: 1,   p: '10px' }}
                            
                            >
                                Gerar
                            </Button>   */}
                        </Box>
                    </Grid>  
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{mt: 1, mb: 1}} >
                        </Grid>                   
                </Grid>
            </Box>
            <div className="chartCard">
                <div className="chartBox">
                    <div className="chartContainer">
                        <Box sx={chartContainerBodyV(filters, labelsCount)}>
                            <Box sx={{height: getHeightChart(filters, labelsCount), overflowY: 'hidden'}}>
                            {ChartVertical}
                           </Box>                             
                        </Box>
                        <Box sx={chartContainerBodyH(filters, labelsCount)}>                    
                            {ChartHorizontal}
                        </Box>
                    </div>
                </div>
            </div>    
        <Dialog open={openConfig} onClose={handleCloseConfig} > 
        <DialogTitle>Opções de Gráficos</DialogTitle>
        <DialogContent> 
        <Loading open={loading} />           
        <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1, mb: 1, }} >
                <FormControl fullWidth>
                    <InputLabel id="chart-option">Qual informação deseja visualizar?</InputLabel>
                    <Select
                        labelId="chart-option"
                        label="Qual informação deseja visualizar?"
                        fullWidth 
                        name='option'
                        value={filters?.option} 
                        onChange={handleChangeFilters}
                    >
                        {/* <MenuItem value={1}><em>Atendimentos Geral - Mês/Ano</em></MenuItem> */  }
                        <MenuItem value={1}><em>Atendimentos Geral por tipo - Mês/Ano</em></MenuItem>
                        <MenuItem value={2}><em>Atendimentos Geral - Período</em></MenuItem>
                        <MenuItem value={3}><em>Atendimentos por Usuário - Dia a Dia</em></MenuItem>
                        <MenuItem value={4}><em>Total de Atendimentos - Período - Ord. Nome</em></MenuItem>
                        <MenuItem value={5}><em>Total de Atendimentos - Período - Ord. Quantidade</em></MenuItem>
                        {/*<MenuItem value={7}><em>Clientes que solicitaram atendimentos no período - Geral</em></MenuItem>*/  }
                    </Select>
                </FormControl>  
            </Grid>              
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display: (filters?.option === 1) ? 'none' : ''}}  >
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DemoContainer components={['DatePicker']} >
                    <DatePicker 
                        label="Data Inicial" 
                        value={dayjs(filters.InitialDate).utc(IsDev)}
                        onChange={(newValue) => handleChangeFiltersDate('InitialDate', newValue)}
                        format="DD/MM/YYYY" 
                        sx={{width: '100%'}}
                    />
                    <DatePicker 
                        label="Data Final" 
                        value={dayjs(filters.FinalDate).utc(IsDev)}
                        onChange={(newValue) => handleChangeFiltersDate('FinalDate', newValue)}
                        format="DD/MM/YYYY" 
                        sx={{width: '100%'}}
                    />               
                    </DemoContainer>         
                </LocalizationProvider>       
            </Grid>             
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{mt: 1, mb: 1, display: (filters?.option === 1) ? '' : 'none'}} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart-period">Mês</InputLabel>
                    <Select
                        labelId="type-chart-period"
                        label="Mês"
                        fullWidth 
                        name='month'
                        value={filters?.month} 
                        onChange={handleChangeFilters}
                    >
                        {months && months.map((month) => { return ( <MenuItem value={month.value}><em>{month.name}</em></MenuItem> )})}     
                    </Select>
                </FormControl>  
            </Grid>  
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{mt: 1, mb: 1, display: (filters?.option === 1) ? '' : 'none'}} >
                <TextField
                    fullWidth
                    label="Ano"
                    type="number"
                    name='year'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={filters?.year}
                    onChange={handleChangeFilters}
                /> 
            </Grid>   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} sx={{display: (filters?.option === 4 || filters?.option === 5) ? 'none' : ''}}>
                <Autocomplete
                disablePortal
                fullWidth
                options={listUsers}
                sx={{mt: 1}}
                getOptionLabel={ (option) => option }  //option['name'] ||  
                value={filters?.userName}
                onChange={(event, values) => handleChangeUser(values)}
                renderInput={(params) => 
                <TextField
                    name="user"
                    {...params} label="Atendente" 
                />}
                />          
            </Grid>                   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart">Tipo de Gráfico</InputLabel>
                    <Select
                        labelId="type-chart"
                        label="Tipo de Gráfico"
                        fullWidth 
                        name='typeChart'
                        value={filters?.typeChart} 
                        onChange={handleChangeFilters}
                    >
                        <MenuItem value={1}><em>Barras</em></MenuItem>
                        <MenuItem value={2}><em>Linhas</em></MenuItem>
                    </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} sx={{display: (filters?.option === 4 || filters?.option === 5) ? 'none' : ''}}>
                <FormControl fullWidth>
                    <InputLabel id="type-chart">Orientação</InputLabel>
                    <Select
                        labelId="orientation-chart"
                        label="Orientação"
                        fullWidth 
                        name='orientation'
                        value={filters?.orientation} 
                        onChange={handleChangeFilters}
                    >
                        <MenuItem value={'y'}><em>Horizontal</em></MenuItem>
                        <MenuItem value={'x'}><em>Vertical</em></MenuItem>
                    </Select>
                </FormControl>  
            </Grid>            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} > 
            <FormControlLabel  control={
                <Checkbox
                    checked={animations}
                    onChange={(e) => setAnimations(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                /> 
                } label="Gráfico Animado" 
            />
 
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color={'warning'} onClick={handleCloseConfig}>Fechar</Button>
            <Button variant="outlined" onClick={updateFilter}>Aplicar</Button>
        </DialogActions>        
        </Dialog>
        <RequestsTableModalCharts values={requestsClick} setOpen={setRequestsClickOpen} setOpenRequest={setOpenRequest} changed={changed} setValues={setValues} open={requestsClickOpen} handleChanged={updateFilter}/>
        <RequestAdmin values={values} open={openRequest} setValues={setValues} setOpenRequest={setOpenRequest} changed={changed} setChanged={setChanged} handleChanged={handleChanged}  />
        </Box>
    )};
}

export default RequestsChartsPage;