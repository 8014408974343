import { create } from 'zustand';
import { FilterState } from '../types/types';


// Cria o store
const useFiltersStore = create<FilterState>((set) => ({
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  option: 1,
  user: 0,
  typeChart: 1,
  orientation: 'x',
  userName: '',
  FilterDate: false,
  InitialDate: new Date().toISOString(),
  FinalDate: new Date().toISOString(),
  orderChart: 'desc',
  setFilters: (filters: any) => set((state) => ({ ...state, ...filters })),
}));

export default useFiltersStore;
